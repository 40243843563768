import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NavigationProps from "./NavigationProps";

const Navigation = loadable(
    (): Promise<DefaultComponent<NavigationProps>> =>
        import(/* webpackChunkName: "Navigation" */ "./Navigation"),
);
const NavigationLoader = (props: NavigationProps): ReactElement => (
    <Navigation {...props} />
);
export default NavigationLoader;
