/* stylelint-disable no-descending-specificity  */
import { HashLink } from "react-router-hash-link";
import { styled, css } from "styled-components";
import theme from "styled-theming";

import Button from "components/Button";
import { default as NavLink } from "components/Link";
import { Container as GridContainer } from "style/components/Page";
import { BodyM } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
    white: "--color-transparent",
    lightgray: "--background-default",
    blue: "--block-blue-default",
    cyan: "--block-cyan-default",
});

const textColor = theme("contentTheme", {
    white: "--text-on-image-primary",
    black: "--text-on-neutral-primary",
    cyan: "--text-on-blue-primary",
    blue: "--text-on-cyan-primary",
});

const logoColor = theme("theme", {
    white: "--icon-on-image-primary",
    lightgray: "--icon-on-neutral-primary",
    blue: "--icon-on-blue-primary-alt3",
    cyan: "--icon-on-cyan-primary",
});

export const activeWrapper = css`
    background-color: var(--background-default);
    color: var(--text-on-neutral-primary);
`;

export const Wrapper = styled.div.attrs<{
    $headerActive: boolean;
    $showMenu: boolean;
    $menuOpen: boolean;
    $searchVisible: boolean;
}>(() => ({}))`
    align-items: center;
    background-color: var(${backgroundColor});
    bottom: ${(props) => (props.$menuOpen ? "0" : "auto")};
    color: var(${textColor});
    display: flex;
    flex-direction: column;
    max-height: ${(props) => (props.$showMenu ? "100vh" : "0")};
    opacity: ${(props) => (props.$showMenu ? ".9999" : "0")};
    overflow: ${(props) => (props.$showMenu ? "visible" : "hidden")};
    position: fixed;
    top: 0;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
    width: 100%;
    z-index: 999;
    ${(props) => props.$headerActive && activeWrapper};

    ${MQ.FROM_XL} {
        bottom: auto;
    }
`;

export const Container = styled(GridContainer)`
    ${columnGap};
    align-items: center;
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr) [col-end];
    grid-template-rows: auto 1fr auto;
    padding-bottom: 0.875rem;
    padding-top: 0.875rem;
    position: relative;
    width: 100%;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr) [col-end];
        padding-bottom: 1.3125rem;
        padding-top: 1.3125rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 2.1875rem;
        padding-top: 2.125rem;
    }
`;

export const Inner = styled.div`
    align-items: center;
    column-gap: 1.3125rem;
    display: flex;
    grid-column: col-start / col-end;
    justify-content: flex-end;
    width: 100%;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr) [col-end];
        column-gap: 2.1875rem;
    }

    nav {
        margin-left: auto;
    }
`;

export const Links = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0;
`;

export const LinkWrapper = styled(BodyM)`
    list-style: none;
    padding: 0.875rem;
`;

export const SearchButton = styled(Button)`
    transition: none;
`;

export const MenuButton = styled(Button)`
    & {
        ${MQ.FROM_M} {
            padding: 0.875rem 0;
        }

        ${MQ.FROM_XL} {
            display: none;
        }
    }
`;

export const LogoLink = styled(NavLink)`
    border-bottom: none;
    color: var(${logoColor});
    margin-right: auto;

    svg {
        height: 2.625rem;

        ${MQ.FROM_M} {
            height: 2.625rem;
        }

        ${MQ.FROM_XL} {
            height: 3.5rem;
        }
    }
`;

export const SkipLinkWrapper = styled.div`
    background: var(--background-default);
    border-radius: 0.25rem;
    box-shadow: var(--modal-shadow);
    display: flex;
    justify-content: center;
    left: 1.75rem;
    position: absolute;
    right: 1.75rem;
    top: 1.75rem;
    z-index: 9999;

    ${MQ.FROM_M} {
        left: 50%;
        right: auto;
        top: 2.625rem;
        transform: translate(-50%, 0);
        width: 31.125rem;
    }

    ${MQ.FROM_XL} {
        top: 3.9375rem;
        width: 34rem;
    }

    &:not(:focus-within) {
        top: -9999px;
    }
`;
export const SkipLink = styled(HashLink)`
    border-radius: 0.25rem;
    color: var(--text-on-neutral-link);
    display: block;
    margin-block: 1.75rem;
    outline-offset: 0;
    padding: 0.4375rem;

    ${MQ.FROM_M} {
        margin-block: 2.1875rem;
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }
`;
