/* stylelint-disable no-descending-specificity */
import { styled, css } from "styled-components";

import Button from "components/Button";
import { default as NavLink } from "components/Link";
import { Container as GridContainer } from "style/components/Page";
import {
    BodyM,
    styleBodyM,
    styleHeadingL,
    styleHeadingM,
    styleHeadingXL,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Wrapper = styled.div.attrs<{
    $open?: boolean;
    $allClosed?: boolean;
}>(() => ({}))`
    align-items: center;
    border-top: 1px solid var(--color-black-o15);
    display: ${(props) => (props.$open ? "flex" : "none")};
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    width: 100%;

    ${MQ.FROM_M} {
        overflow: hidden;
    }

    ${MQ.FROM_XL} {
        display: ${(props) =>
            props.$open && !props.$allClosed ? "flex" : "none"};
        height: auto;
        max-height: none;
        overflow: auto;
    }
`;

export const Container = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template:
        "topNav subNav" 1fr
        "mainLinks subNav" auto
        "loginButton subNav" auto;
    grid-template-columns: repeat(4, [col-start] 1fr);
    height: 100%;
    padding-bottom: 2.25rem;
    padding-top: 1.3125rem;

    ${MQ.FROM_M} {
        grid-template:
            "topNav subNav" 1fr
            "mainLinks subNav" auto;
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-top: 2.625rem;
    }

    ${MQ.FROM_XL} {
        grid-template: "topNav subNav" auto;
        grid-template-columns: repeat(12, [col-start] 1fr);
        height: auto;
        overflow: visible;
        padding-bottom: 4.375rem;
        padding-top: 3.5rem;
    }
`;

export const TopNav = styled.ul.attrs<{
    $allClosed?: boolean;
}>(() => ({}))`
    ${styleHeadingM}
    color: var(
        ${(props) => (props.$allClosed ? "--color-black" : "--color-black-o60")}
    );
    grid-area: topNav;
    grid-column: 1 / span 4;
    list-style: none;
    margin: 0;
    margin-top: 3.5rem;
    padding: 0;
    ${(props) =>
        !props.$allClosed &&
        css`
            display: none;
        `}

    li {
        margin-bottom: 1.3125rem;
    }

    ${MQ.FROM_M} {
        margin-top: 0;
        display: block;
        grid-column: 1 / span 6;
    }

    ${MQ.FROM_L} {
        grid-column: 1 / span 4;
    }

    ${MQ.FROM_XL} {
        display: none;
    }
`;

export const MainLinks = styled.ul.attrs<{
    $show?: boolean;
}>(() => ({}))`
    ${styleBodyM}
    align-self: flex-end;
    color: var(--text-on-neutral-primary);
    grid-area: mainLinks;
    grid-column: 1 / span 4;
    list-style: none;
    margin: 0;
    margin-top: 1.75rem;
    padding: 0;
    ${(props) =>
        !props.$show &&
        css`
            display: none;
        `}

    li {
        margin-bottom: 0.4375rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ${MQ.FROM_M} {
        display: block;
        grid-column: 1 / span 6;
    }

    ${MQ.FROM_L} {
        grid-column: 1 / span 4;
    }

    ${MQ.FROM_XL} {
        display: none;
    }
`;

export const MainLink = styled(NavLink)`
    span {
        border-bottom: 1px solid var(--color-black-o00);
        color: var(--text-on-neutral-primary);
        transition: border-color 0.2s ease-in-out;
    }

    &:hover {
        span {
            border-color: var(--text-on-neutral-primary);
        }
    }
`;

export const LoginButton = styled(Button).attrs<{
    $show?: boolean;
}>(() => ({}))`
    && {
        display: ${(props) => (props.$show ? "block" : "none")};
        grid-area: loginButton;
        grid-column: 1 / span 4;
        justify-self: flex-start;
        margin-top: 2.625rem;

        ${MQ.FROM_M} {
            display: none;
        }
    }
`;

export const SubNavWrapper = styled.div.attrs<{
    $open?: boolean;
}>(() => ({}))`
    ${columnGap};
    display: ${(props) => (props.$open ? "grid" : "none")};
    grid-area: subNav;
    grid-column: 1 / span 4;
    grid-template-columns: repeat(4, [col-start] 1fr);
    grid-template-rows: auto auto 1fr;
    max-height: 100%;

    ${MQ.FROM_M} {
        grid-column: 7 / span 6;
        overflow: auto;
        grid-template-rows: auto 1fr;
    }

    ${MQ.FROM_L} {
        grid-column: 5 / span 8;
        overflow: hidden;
        grid-template-rows: auto;
    }

    ${MQ.FROM_XL} {
        grid-column: 1 / span 12;
        overflow: visible;
    }
`;

export const Description = styled.div`
    grid-column: 1 / span 4;

    ${MQ.FROM_M} {
        grid-column: 1 / span 4;
    }

    ${MQ.FROM_L} {
        grid-column: 1 / span 2;
    }

    ${MQ.FROM_XL} {
        grid-column: 1 / span 1;
    }
`;

export const Title = styled(NavLink)`
    ${styleHeadingXL}
    border-bottom: none;
    color: inherit;

    svg {
        margin-left: 0.875rem;
        transition:
            margin-left 0.2s ease-in-out,
            margin-right 0.2s ease-in-out;
        margin-right: 0.875rem;
    }

    &:hover {
        svg {
            margin-left: 1.75rem;
            margin-right: 0;
        }
    }

    ${MQ.FROM_M} {
        ${styleHeadingL}
        color: inherit;
    }
`;

export const Introduction = styled(BodyM)`
    ${styleBodyM};
    color: inherit;
    margin-top: 0.4375rem;

    ${MQ.FROM_M} {
        margin-top: 0.875rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 1.3125rem;
    }
`;

export const SubNavContainer = styled.div`
    grid-column: 1 / span 4;
    margin-top: 1.75rem;

    ${MQ.FROM_M} {
        grid-column: 1 / span 4;
        padding-bottom: 0;
    }

    ${MQ.FROM_L} {
        grid-column: 3 / span 2;
        margin-top: 0;
        overflow: auto;
    }

    ${MQ.FROM_XL} {
        grid-column: 2 / span 3;
    }
`;

export const linkStyle = css`
    all: unset;
    border-bottom: none;
    color: inherit;
    cursor: pointer;
    display: block;

    svg {
        vertical-align: top;
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    span {
        border-bottom: 1px solid var(--color-black-o00);
        color: var(--color-black);
        transition: border-color 0.2s ease-in-out;
    }

    &:hover {
        span {
            border-color: var(--text-on-neutral-primary);
        }
    }

    &.active {
        color: var(--text-on-neutral-primary);
    }
`;

export const Link = styled(NavLink)`
    ${linkStyle}
`;

export const LinkButton = styled.button`
    align-items: flex-start;
    ${linkStyle}
    display: flex;
    gap: 0.875rem;
    justify-content: space-between;
    width: 100%;

    svg {
        flex: 0 0 1.75rem;
    }
`;

export const BackButton = styled(Button)`
    && {
        grid-column: 1 / span 4;
        margin-bottom: 1.75rem;
        margin-right: auto;

        ${MQ.FROM_M} {
            display: none;
            margin-right: auto;
        }
    }
`;
