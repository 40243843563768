import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SearchOverlayProps from "./SearchOverlayProps";

const SearchOverlay = loadable(
    (): Promise<DefaultComponent<SearchOverlayProps>> =>
        import(/* webpackChunkName: "SearchOverlay" */ "./SearchOverlay"),
);
const SearchOverlayLoader = (props: SearchOverlayProps): ReactElement => (
    <SearchOverlay {...props} />
);
export default SearchOverlayLoader;
