import { useEffect, useState } from "react";

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    useEffect(() => {
        const updateScrollPosition = () => {
            setScrollPosition(scrollY > 0 ? scrollY : 0);
        };

        window.addEventListener("scroll", updateScrollPosition);

        return () => {
            window.removeEventListener("scroll", updateScrollPosition);
        };
    }, [scrollPosition]);

    return scrollPosition;
};

const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState<string>("up");

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (
                direction !== scrollDirection &&
                (scrollY > lastScrollY || scrollY < lastScrollY)
            ) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };

        window.addEventListener("scroll", updateScrollDirection);

        return () => {
            window.removeEventListener("scroll", updateScrollDirection);
        };
    }, [scrollDirection]);

    return scrollDirection;
};

export default useScrollDirection;
