import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NavigationMegaItemsProps from "./NavigationMegaItemsProps";

const NavigationMegaItems = loadable(
    (): Promise<DefaultComponent<NavigationMegaItemsProps>> =>
        import(
            /* webpackChunkName: "NavigationMegaItems" */ "./NavigationMegaItems"
        ),
);
const NavigationMegaItemsLoader = (
    props: NavigationMegaItemsProps,
): ReactElement => <NavigationMegaItems {...props} />;
export default NavigationMegaItemsLoader;
